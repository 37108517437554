import styled, { css, keyframes } from 'styled-components';
import { IconWrapper } from '../../Common/Icon/style';
import { DatePickerWrapper } from '../../Modals/UploadModal/EditRegion/Details/style';

const FadeInOut = keyframes`
  from {
    opacity: 1;
  }
  to {
      opacity: 0;
  }
`;

const animateCss = css`
  animation: ${FadeInOut} 2s alternate infinite;
`;
const leftMapToolsAndSidebarVisible = css`
  left: calc(-100vw + 1024px);
`;
const widthMapToolsAndSidebarVisible = css`
  width: calc(100vw - 1044px);
`;
const leftMapToolsVisibleAndSidebarHidden = css`
  left: calc(-100vw + 392px);
`;
const widthMapToolsVisibleAndSidebarHidden = css`
  width: calc(100vw - 412px);
`;
const leftNoneVisible = css`
  left: calc(-100vw + 86px);
`;
const widthNoneVisible = css`
  width: calc(100vw - 106px);
`;
const leftMapToolsHiddenAndSidebarVisible = css`
  left: calc(-100vw + 716px);
`;
const widthMapToolsHiddenAndSidebarVisible = css`
  width: calc(100vw - 736px);
`;

export const SliderWrapper = styled.div<{
  active?: boolean;
  isDownloading?: boolean;
  isMapToolsExpanded?: boolean;
  isSidebarVisible?: boolean;
}>`
  position: fixed;
  z-index: -1;
  bottom: 24px;
  left: calc(
    -100vw + ${({ isMapToolsExpanded, isSidebarVisible }) => (isMapToolsExpanded && isSidebarVisible ? '1022px' : isMapToolsExpanded ? '372px' : '52px')}
  );
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  ${({ isMapToolsExpanded, isSidebarVisible }) => !isMapToolsExpanded && !isSidebarVisible && leftNoneVisible}
  ${({ isMapToolsExpanded, isSidebarVisible }) =>
    isMapToolsExpanded && !isSidebarVisible && leftMapToolsVisibleAndSidebarHidden}
  ${({ isMapToolsExpanded, isSidebarVisible }) =>
    !isMapToolsExpanded && isSidebarVisible && leftMapToolsHiddenAndSidebarVisible}
  ${({ isMapToolsExpanded, isSidebarVisible }) =>
    isMapToolsExpanded && isSidebarVisible && leftMapToolsAndSidebarVisible}
  ${({ isMapToolsExpanded, isSidebarVisible }) =>
    isMapToolsExpanded && isSidebarVisible && widthMapToolsAndSidebarVisible}
  ${({ isMapToolsExpanded, isSidebarVisible }) =>
    isMapToolsExpanded && !isSidebarVisible && widthMapToolsVisibleAndSidebarHidden}
  ${({ isMapToolsExpanded, isSidebarVisible }) =>
    !isMapToolsExpanded && isSidebarVisible && widthMapToolsHiddenAndSidebarVisible}
  ${({ isMapToolsExpanded, isSidebarVisible }) => !isMapToolsExpanded && !isSidebarVisible && widthNoneVisible}
  opacity: ${({ active }) => (active ? 1 : 0)};
  transition: ${({ theme }) => theme.transition};
  transform: ${({ active }) => (active ? 'translate3d(0, 0, 0)' : 'translate3d(0, 50px, 0)')};
  pointer-events: ${({ active }) => (active ? 'all' : 'none')};
  transition-delay: ${({ active }) => (!active ? '300ms' : '0ms')};

  &:before {
    content: '';
    position: fixed;
    bottom: -24px;
    left: -22px;
    width: 100vw;
    height: 100%;
    z-index: ${({ isDownloading }) => (isDownloading ? '1' : '-1')};
    background: linear-gradient(180deg, rgba(25, 43, 52, 0) 0%, #192b34 76.56%);
    transition: opacity 300ms ease-in-out, transform 300ms ease-in-out;
    opacity: ${({ active }) => (active ? 1 : 0)};
    pointer-events: ${({ isDownloading }) => (isDownloading ? 'all' : 'none')};
    transform: ${({ active }) => (active ? 'translate3d(0, 0, 0)' : 'translate3d(0, 50px, 0)')};
    transition-delay: ${({ active, isDownloading }) => (active && !isDownloading ? '300ms' : '0ms')};
  }

  &:after {
    content: 'Downloading images. Please wait...';
    position: absolute;
    bottom: -100px;
    left: 0;
    ${({ isMapToolsExpanded, isSidebarVisible }) =>
      isMapToolsExpanded && isSidebarVisible && widthMapToolsAndSidebarVisible}
    ${({ isMapToolsExpanded, isSidebarVisible }) =>
      isMapToolsExpanded && !isSidebarVisible && widthMapToolsVisibleAndSidebarHidden}
  ${({ isMapToolsExpanded, isSidebarVisible }) =>
      !isMapToolsExpanded && isSidebarVisible && widthMapToolsHiddenAndSidebarVisible}
  ${({ isMapToolsExpanded, isSidebarVisible }) => !isMapToolsExpanded && !isSidebarVisible && widthNoneVisible}
    height: 100%;
    color: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 3;
    opacity: ${({ isDownloading }) => (isDownloading ? 1 : 0)};
    transition: ${({ theme }) => theme.transition};
    font-weight: bold;
    ${({ isDownloading }) => (isDownloading ? animateCss : null)}
    pointer-events: none;
  }
`;

export const SliderSlides = styled.div<{
  isMapToolsExpanded?: boolean;
}>`
  display: flex;
  align-items: flex-end;
  justify-content: flex-start;
  gap: 14px;
  position: relative;
  width: 100%;
  overflow-x: scroll;
  overflow-y: visible;
  height: 293px;
  padding-bottom: 46px;
  margin-bottom: -60px;

  &::-webkit-scrollbar {
    width: 8px;
    height: 12px;
    background-color: rgba(217, 217, 217, 0.2);
    border-radius: 8px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #b5b5b5;
    border-radius: 30px;
    height: 10px;
  }

  &:before {
    content: '';
    position: fixed;
    bottom: 102px;
    left: 150px;
    width: calc(100% - 150px);
    height: 1px;
    background-color: ${({ theme }) => theme.colors.lightBlueTransparent};
  }
`;

export const DownloadButton = styled.button`
  position: absolute;
  bottom: 5px;
  left: 5px;
  width: calc(100% - 10px);
  border-radius: 12px;
  padding: 5px;
  color: ${({ theme }) => theme.colors.blackI};
  display: flex;
  justify-content: center;
  align-items: center;
  opacity: 0;
  pointer-events: none;
  background-color: ${({ theme }) => theme.colors.purple};
  transition: ${({ theme }) => theme.transition};
  cursor: pointer;
  font-weight: bold;

  ${IconWrapper} {
    margin-right: 5px;
  }

  &:hover {
    opacity: 0.6 !important;
  }
`;

export const SliderSlide = styled.div<{
  active?: boolean;
  timelineText: string;
  isLoading?: boolean;
}>`
  display: flex;
  flex-shrink: 0;
  justify-content: center;
  align-items: center;
  width: 70px;
  height: 30px;
  opacity: 1;
  margin: 0;
  position: relative;
  transition: ${({ theme }) => theme.transition};
  overflow-y: visible;
  bottom: -25px;
  * {
    cursor: pointer;
  }

  &:hover {
    &::before,
    &::after {
      opacity: 0.5;
    }
  }
  ${({ isLoading }) =>
    isLoading &&
    `
    opacity: 0.2;
    cursor: not-allowed;
    pointer-events: none;
  `}

  img {
    position: absolute;
    width: 100%;
    height: 100%;
    border-radius: inherit;
    top: 0;
    left: 0;
    object-fit: contain;
    // we are still loading images to speed up the load process on the map
    opacity: 0;
  }

  &::before,
  &:after {
    transition: ${({ theme }) => theme.transition};
  }

  &:before {
    content: ${({ timelineText }) => `'${timelineText}'`};
    font-weight: 500;
    font-size: 11px;
    line-height: 13px;
    color: ${({ theme, active }) => (active ? theme.colors.purple : theme.colors.lightBlueTransparent)};
    position: absolute;
    bottom: -8px;
    left: 50%;
    transform: translate3d(-50%, 0, 0);
    cursor: pointer;
  }
  &:after {
    content: '';
    width: ${({ active }) => (active ? '8px' : '7px')};
    height: ${({ active }) => (active ? '8px' : '7px')};
    border-radius: 50%;
    background-color: ${({ theme }) => theme.colors.white};
    position: absolute;

    bottom: 8px;
    left: calc(50% - 3.5px);
    border: ${({ active }) => (active ? '2px solid var(--purple)' : 'none')};
  }
`;

export const SliderNavigationWrapper = styled.div<{
  active?: boolean;
  disabled?: boolean;
}>`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 82px;
  width: 100%;
  opacity: ${({ active }) => (active ? 1 : 0)};
  transition: ${({ theme }) => theme.transition};
  transform: ${({ active }) => (active ? 'translate3d(0, 0, 0)' : 'translate3d(0, 50px, 0)')};
  transition-delay: 300ms;
  pointer-events: ${({ disabled }) => (disabled ? 'none' : 'all')};
`;

export const SliderButtonsWrapper = styled.div`
  display: flex;
  gap: 16px;
`;

export const SliderControlButton = styled.button<{
  rotateIcon?: boolean;
  isPlay?: boolean;
}>`
  width: 32px;
  height: 32px;
  background: ${({ theme }) => theme.colors.newGrey};
  border: 2px solid rgba(255, 255, 255, 0.08);
  border-radius: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  transition: ${({ theme }) => theme.transition};
  transform: ${({ rotateIcon }) => (rotateIcon ? 'rotate(180deg)' : 'rotate(0deg)')};

  &:hover {
    ${IconWrapper} {
      opacity: 0.6;
    }
  }

  ${IconWrapper} {
    transition: ${({ theme }) => theme.transition};
    margin-left: ${({ isPlay }) => (isPlay ? '3px' : 0)};
    mask-size: cover;
  }
`;

export const SliderCTAWrapper = styled.div`
  display: flex;
  gap: 16px;
`;

export const SliderCTA = styled.button<{
  isMapToolsExpanded?: boolean;
  isSidebarVisible?: boolean;
  isSingleDownload?: boolean;
}>`
  border-radius: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid ${({ theme, isSingleDownload }) => (isSingleDownload ? theme.colors.purple : theme.colors.white)};
  background-color: transparent;
  font-weight: 600;
  font-size: 12px;
  line-height: 18px;
  color: ${({ theme, isSingleDownload }) => (isSingleDownload ? theme.colors.purple : theme.colors.white)};
  width: auto;
  padding: 8px 12px;
  white-space: nowrap;
  cursor: pointer;
  transition: ${({ theme }) => theme.transition};
  overflow: visible;
  z-index: 1;

  &:hover {
    opacity: 0.6;
  }

  ${IconWrapper} {
    margin-right: 10px;
  }

  span {
    &:first-of-type {
      display: ${({ isMapToolsExpanded, isSidebarVisible }) =>
        isMapToolsExpanded && isSidebarVisible ? 'none' : 'block'};
      @media screen and (max-width: 1350px) {
        display: none;
      }
    }
    &:nth-of-type(2) {
      display: ${({ isMapToolsExpanded, isSidebarVisible }) =>
        isMapToolsExpanded && isSidebarVisible ? 'block' : 'none'};
      @media screen and (max-width: 1350px) {
        display: block;
      }
    }
  }
`;

export const SliderDatePickerWrapper = styled(DatePickerWrapper)`
  bottom: -63px;
  width: fit-content;
  align-self: flex-end;
  margin-bottom: 0;

  .rs-input-group.rs-input-group-inside input {
    font-size: 12px;
    text-align: center;
    padding-left: 0;
  }
`;
