import { IBiomass } from '../../../types/Geo';

export const sliceBiomassDataByTimestamps = (biomassData: IBiomass, timeStart: string, timeEnd: string) => {
  const newTimes = biomassData.times.filter(
    (time) => new Date(time) >= new Date(timeStart) && new Date(time) <= new Date(timeEnd)
  );
  const newData = {
    ...biomassData,
    data: biomassData.data.filter((_, index) => newTimes.includes(biomassData.times[index])),
    times: newTimes
  };

  if (biomassData.images) {
    newData.images = biomassData.images.filter((_, index) => newTimes.includes(biomassData.times[index]));
  }
  return newData;
};
