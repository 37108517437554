import { useTranslation } from 'react-i18next';
import { useActionsMenu } from '../../../hooks/useActionsMenu';
import { openModal } from '../../../redux/features/modal/modal-slice';

import { useAppDispatch, useAppSelector } from '../../../redux/hooks';
import Icon from '../../Common/Icon';
import { FloatingButton, Wrapper } from './style';

interface Props {
  isDataLoading: boolean;
}

const FloatingActionsButtons = ({ isDataLoading }: Props) => {
  const { handleDownloadGeoJson, handleDownloadCSV } = useActionsMenu();
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const { user } = useAppSelector((state) => state.userState);
  const { sidebarVisible } = useAppSelector((state) => state.uiState);

  return (
    <Wrapper visible={sidebarVisible}>
      {user?.role !== 'free' ? (
        <FloatingButton onClick={handleDownloadGeoJson}>
          <Icon variant="GEOJSON" color="green" size={22} />
          {t('GeoJSON')}
        </FloatingButton>
      ) : null}
      <FloatingButton onClick={handleDownloadCSV} disabled={isDataLoading}>
        <Icon variant="CSV" color="white" size={22} />
        {t('CSV')}
      </FloatingButton>
      {user?.role !== 'free' ? (
        <FloatingButton onClick={() => dispatch(openModal('pdfReport'))} disabled={isDataLoading}>
          <Icon variant="PDF" color="purple" size={22} />
          {t('PDF')}
        </FloatingButton>
      ) : null}
    </Wrapper>
  );
};

export default FloatingActionsButtons;
