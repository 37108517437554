import styled from 'styled-components';
import { Props } from '.';

export const ICON_VARIANTS = {
  ARROW_RIGHT_SKIP: 'arrow-right-skip.svg',
  ARROW_RIGHT: 'arrow-right.svg',
  ARROWS_RIGHT: 'arrows-right.svg',
  ARROW_LEFT: 'arrow-left.svg',
  BELL: 'bell.svg',
  BELL_OUTLINE: 'bell-outline.svg',
  BELL_FILLED: 'bell-filled.svg',
  CALENDAR: 'calendar.svg',
  CHECK: 'check.svg',
  CARET: 'caret.svg',
  CHEVRON_DOWN: 'chevron-down.svg',
  CHEVRON_RIGHT: 'chevron-right.svg',
  CSV: 'csv.svg',
  DOWNLOAD: 'download.svg',
  DRAW: 'draw.svg',
  DRAW_BOLD: 'draw-bold.svg',
  EDIT: 'pencil.svg',
  ENVELOPE: 'envelope.svg',
  EXCLAMATION_CIRCLE: 'exclamation-circle.svg',
  EYE_HIDE: 'eye-hide.svg',
  EYE_SHOW: 'eye-show.svg',
  FOLDER: 'folder.svg',
  GEOJSON: 'geojson.svg',
  INFO_CIRCLE: 'info-circle.svg',
  KEY: 'key.svg',
  LAYERS: 'layers.svg',
  LIGHTBULB: 'lightbulb.svg',
  LIST: 'list.svg',
  MAP_PIN: 'map-pin.svg',
  MINUS: 'plus.svg',
  MORE: 'more.svg',
  PAUSE: 'pause.svg',
  PDF: 'pdf.svg',
  PENCIL: 'pencil.svg',
  PIN: 'pin.svg',
  PLAY: 'play.svg',
  PLAY_CIRCLE: 'play-circle.svg',
  PLUS: 'plus.svg',
  RIGHT_ARROW: 'right-arrow.svg',
  SEARCH: 'search.svg',
  SELECT: 'select.svg',
  SELECT_CHECKED: 'select-checked.svg',
  SORT: 'sort.svg',
  THREE_DOTS_HORIZONTAL: 'three-dots-horizontal.svg',
  TICK_CIRCLE: 'tick-circle.svg',
  TRASH: 'trash.svg',
  UPLOAD: 'file-upload.svg',
  X_CIRCLE_OUTLINE: 'x-circle-outline.svg',
  X_CIRCLE: 'x-circle.svg',
  X: 'x.svg',
  UNASSIGNED: 'unassigned.svg',
  USER: 'user.svg',
  UPLOAD_BOLD: 'upload-bold.svg',
  WARNING: 'warning.svg'
};

export const IconWrapper = styled.div<Props>`
  width: ${({ size }) => size}px;
  height: ${({ size }) => size}px;
  background-color: ${({ color, theme }) => theme.colors[color]};
  mask-image: url(/assets/icons/${({ variant }) => ICON_VARIANTS[variant]});
  mask-position: center;
  mask-repeat: no-repeat;
  mask-size: 100%;
`;
