export const INITIAL_MAP_VIEW_STATE = {
  latitude: -1.4563305888363516,
  longitude: 20.39429841555591,
  zoom: 5.2
};

export const MAPBOX_MAP_STYLES = {
  satellite: 'mapbox://styles/clevertech-cyclops/cl9r87snp006r14p0bq13k7ea',
  monochrome: 'mapbox://styles/clevertech-cyclops/clg28lksu001s01mptwtolpvy',
  navigation: 'mapbox://styles/clevertech-cyclops/clg17sr77003u01nr5c1cfwm8'
};

export const TILE_LAYERS = {
  FILLS: 'tile-fills',
  OUTLINE: 'tile-outlines'
};

export const USER_TILE_LAYERS = {
  FILLS: 'user-tile-fills',
  OUTLINE: 'user-tile-outlines'
};

export const SPLIT_TILE_LAYERS_FILLED = {
  FILLS: 'split-tile-fills-filled',
  OUTLINE: 'split-tile-outlines-filled'
};

export const SPLIT_TILE_LAYERS_UNFILLED = {
  FILLS: 'split-tile-fills-unfilled',
  OUTLINE: 'split-tile-outlines-unfilled'
};

export const PUBLIC_TILE_LAYERS = {
  FILLS: 'public-tile-fills',
  OUTLINE: 'public-tile-outlines'
};

export const SOURCE_NAME = 'tiles';
export const USER_SOURCE_NAME = 'user-tiles';
export const SPLIT_DRAWN_SOURCE_NAME_FILELD = 'split-drawn-tiles-filled';
export const SPLIT_DRAWN_SOURCE_NAME_UNFILLED = 'split-drawn-tiles-unfilled';
export const PUBLIC_SOURCE_NAME = 'public-tiles';
export const DEFORESTATION_RASTER = 'deforestation-raster';
