import { ClickAwayListener } from '@mui/material';
import Tooltip from '@mui/material/Tooltip';
import { MouseEvent, RefObject, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

import useDownloadImages from '../../../../../hooks/useDownloadImages';
import { toggleDeforestationComponent } from '../../../../../redux/features/ui/ui-slice';
import { useAppDispatch } from '../../../../../redux/hooks';
import { IBiomass } from '../../../../../types/Geo';
import Icon from '../../../../Common/Icon';
import DeforestationImage from '../../../../DataBoard/Deforestation/DeforestationImage';
import {
  DownloadButton,
  SliderButtonsWrapper,
  SliderControlButton,
  SliderCTA,
  SliderNavigationWrapper,
  SliderSlide,
  SliderSlides,
  SliderWrapper
} from './style';

interface Props {
  visible?: boolean;
  dataTestId?: string;
  deforestationData: IBiomass;
  hideCloseButton?: boolean;
}

const Deforestation = ({ visible, dataTestId, deforestationData, hideCloseButton = false }: Props) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const [tooltipOpen, setTooltipOpen] = useState(false);
  const [activeIndex, setActiveIndex] = useState(0);
  const [isPlaying, setIsPlaying] = useState(false);
  const sliderRef: RefObject<HTMLDivElement> = useRef(null);
  const [isUpdating, setIsUpdating] = useState(false);
  const [imageReadyStates, setImageReadyStates] = useState<boolean[]>(
    // start loading the last (more recent) image
    deforestationData.images.map((_, index) => index === deforestationData.images.length - 1)
  );
  const { isDownloading, downloadSingleImage, downloadImages } = useDownloadImages();

  const isAllImagesReady = useMemo(() => imageReadyStates.every((el) => el), [imageReadyStates]);

  const handleOnClose = useCallback(
    (e: MouseEvent<HTMLButtonElement>) => {
      e.preventDefault();
      dispatch(toggleDeforestationComponent());
    },
    [dispatch]
  );

  const scrollToActiveElement = useCallback(() => {
    const container = sliderRef.current;
    if (container) {
      const activeElement = container.querySelector('.active');
      if (activeElement) {
        const containerRect = container.getBoundingClientRect();
        const activeRect = activeElement.getBoundingClientRect();
        const isFullyVisible = activeRect.left >= containerRect.left && activeRect.right <= containerRect.right;
        if (!isFullyVisible) {
          let scrollPosition = container.scrollLeft;
          if (activeRect.left < containerRect.left) {
            scrollPosition += activeRect.left - containerRect.left;
          } else if (activeRect.right > containerRect.right) {
            scrollPosition += activeRect.right - containerRect.right;
          }
          container.scrollTo({
            left: scrollPosition,
            behavior: 'smooth'
          });
        }
      }
    }
  }, []);

  const handleNextClick = useCallback(() => {
    setIsPlaying(false);
    if (deforestationData) {
      const lastIndex = deforestationData.images.length - 1;
      setActiveIndex((prevIndex) => (prevIndex === lastIndex ? 0 : prevIndex + 1));
      scrollToActiveElement();
    }
  }, [deforestationData, scrollToActiveElement]);

  const handlePrevClick = useCallback(() => {
    setIsPlaying(false);
    if (deforestationData) {
      const lastIndex = deforestationData.images.length - 1;
      setActiveIndex((prevIndex) => (prevIndex === 0 ? lastIndex : prevIndex - 1));
      scrollToActiveElement();
    }
  }, [deforestationData, scrollToActiveElement]);

  const handleFirstClick = useCallback(() => {
    setIsPlaying(false);
    setActiveIndex(0);
  }, []);

  const handleLastClick = useCallback(() => {
    setIsPlaying(false);
    if (deforestationData) {
      setActiveIndex(deforestationData.images.length - 1);
    }
  }, [deforestationData]);

  const handleSlideClick = useCallback((index: number) => {
    setIsPlaying(false);
    setActiveIndex(index);
  }, []);

  const handlePlayClick = useCallback(() => {
    setIsPlaying((wasPlaying) => !wasPlaying);
  }, []);

  useEffect(() => {
    let intervalId: NodeJS.Timer | undefined;
    if (isPlaying && deforestationData) {
      intervalId = setInterval(() => {
        if (activeIndex < deforestationData?.images.length - 1) {
          setActiveIndex((prevIndex) => prevIndex + 1);
        } else {
          setActiveIndex(0);
        }
      }, 5000);
    }
    return () => {
      clearInterval(intervalId);
      setIsPlaying(false);
    };
  }, [isPlaying, activeIndex, deforestationData, scrollToActiveElement]);

  useEffect(() => {
    scrollToActiveElement();
  }, [activeIndex, scrollToActiveElement]);

  // initial focus set to last, more recent, image
  useEffect(() => {
    setActiveIndex(deforestationData.images.length - 1);
  }, [deforestationData, setActiveIndex]);

  const handleCanvasReady = useCallback(
    (index: number) => {
      const haveAnyFalse = imageReadyStates.some((el) => !el);
      const lastWithTrue = imageReadyStates.indexOf(true);

      if (haveAnyFalse && index === lastWithTrue) {
        if (!isUpdating) {
          setIsUpdating(true);

          setTimeout(() => {
            const newImageReadyStates = [...imageReadyStates];
            if (!newImageReadyStates[index - 1]) {
              newImageReadyStates[index - 1] = true;
              setImageReadyStates(newImageReadyStates);
            }

            setIsUpdating(false);
          }, 0);
        }
      }
    },
    [imageReadyStates, isUpdating]
  );

  const renderedSlides = useMemo(
    () =>
      deforestationData.images.map((_, index) => (
        <SliderSlide
          className={activeIndex === index ? 'active' : ''}
          timelineText={deforestationData ? new Date(deforestationData.times[index]).toLocaleDateString() : ''}
          data-test-id={dataTestId && `${dataTestId}-slide-${index}`}
          active={activeIndex === index}
          key={`Deforestation-image-${index}`}
          onClick={!isAllImagesReady ? undefined : () => handleSlideClick(index)}
          isLoading={!imageReadyStates[index]}
        >
          {imageReadyStates[index] ? (
            <DeforestationImage
              id={`Deforestation-image-${index}`}
              dataTestId={dataTestId && `${dataTestId}-image-${index}`}
              onReady={!isAllImagesReady ? () => handleCanvasReady(index) : undefined}
              data={deforestationData?.images[index]}
            />
          ) : null}
          <DownloadButton
            onClick={(e) => {
              e.stopPropagation();
              void downloadSingleImage(index);
            }}
          >
            <Icon color="blackI" variant="DOWNLOAD" size={24} />
            {activeIndex === index ? t('Download image') : ''}
          </DownloadButton>
        </SliderSlide>
      )),
    [
      activeIndex,
      dataTestId,
      deforestationData,
      downloadSingleImage,
      handleCanvasReady,
      handleSlideClick,
      imageReadyStates,
      isAllImagesReady,
      t
    ]
  );

  return (
    <SliderWrapper active={visible} data-test-id={dataTestId} isDownloading={isDownloading}>
      <SliderSlides ref={sliderRef} onScroll={() => (!isPlaying ? setIsPlaying(false) : undefined)}>
        {renderedSlides}
      </SliderSlides>
      <SliderNavigationWrapper active={visible} disabled={!isAllImagesReady}>
        {!hideCloseButton ? (
          <SliderCTA onClick={handleOnClose}>
            <Icon variant="X" color="white" size={18} />
            {t('Close')}
          </SliderCTA>
        ) : null}
        <SliderButtonsWrapper>
          <SliderControlButton rotateIcon onClick={handleFirstClick}>
            <Icon variant="ARROW_RIGHT_SKIP" size={13} color="white" />
          </SliderControlButton>
          <SliderControlButton rotateIcon onClick={handlePrevClick}>
            <Icon variant="ARROW_RIGHT" size={13} color="white" />
          </SliderControlButton>
          <SliderControlButton isPlay={!isPlaying} onClick={handlePlayClick}>
            <Icon variant={isPlaying ? 'PAUSE' : 'PLAY'} size={isPlaying ? 20 : 16} color="white" />
          </SliderControlButton>
          <SliderControlButton onClick={handleNextClick}>
            <Icon variant="ARROW_RIGHT" size={13} color="white" />
          </SliderControlButton>
          <SliderControlButton onClick={handleLastClick}>
            <Icon variant="ARROW_RIGHT_SKIP" size={13} color="white" />
          </SliderControlButton>
        </SliderButtonsWrapper>
        <div
          style={{
            position: 'relative'
          }}
        >
          <ClickAwayListener onClickAway={() => setTooltipOpen(false)}>
            <Tooltip
              PopperProps={{
                disablePortal: true
              }}
              placement={'left-start'}
              onClose={() => {
                setTooltipOpen(false);
              }}
              open={tooltipOpen}
              disableFocusListener
              disableHoverListener
              disableTouchListener
              title={t('Images before 2018 are in 30m resolution. Images after 2018 are in 10m resolution.')}
            >
              <div
                style={{
                  position: 'absolute',
                  top: '-8px',
                  right: '-8px'
                }}
                onClick={() => setTooltipOpen(true)}
              >
                <Icon variant="INFO_CIRCLE" color="white" size={14} />
              </div>
            </Tooltip>
          </ClickAwayListener>
          <SliderCTA onClick={downloadImages}>
            <Icon variant="DRAW" color="white" size={18} />
            {t('Download Images')}
          </SliderCTA>
        </div>
      </SliderNavigationWrapper>
    </SliderWrapper>
  );
};

export default Deforestation;
