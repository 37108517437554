import styled from 'styled-components';
import { IconWrapper } from '../../Common/Icon/style';

export const TopButtonsWrapper = styled.div`
  display: flex;
  gap: 20px;
`;

export const TransparentOutlineButton = styled.button`
  font-weight: 500;
  font-size: 12px;
  line-height: 14px;
  color: ${({ theme }) => theme.colors.white};
  margin-bottom: 20px;
  border: 0;
  background-color: transparent;
  padding: 9px 12px;
  cursor: pointer;
  transition: ${({ theme }) => theme.transition};
  display: flex;
  align-items: center;
  border: 1px solid ${({ theme }) => theme.colors.white};
  border-radius: 8px;
  height: 32px;

  &:hover {
    background-color: ${({ theme }) => theme.colors.white};
    color: ${({ theme }) => theme.colors.black};
    ${IconWrapper} {
      background-color: ${({ theme }) => theme.colors.black};
    }
  }

  ${IconWrapper} {
    margin-top: -2px;
    transition: ${({ theme }) => theme.transition};
    transform: translate3d(-5px, 0, 0);
  }
  &:last-of-type {
    ${IconWrapper} {
      margin-top: 0;
    }
    &:hover {
      ${IconWrapper} {
        transform: translate3d(-2px, 0, 0);
      }
    }
  }
`;

export const TextSections = styled.div`
  display: flex;
  gap: 25px;
  max-width: 505px;
`;

export const TextSection = styled.div`
  display: flex;
  flex-direction: column;
`;

export const TextTitle = styled.p`
  text-transform: capitalize;
  font-weight: 700;
  font-size: 14px;
  color: ${({ theme }) => theme.colors.gray};
  margin-bottom: 8px;
`;

export const Text = styled.p`
  font-weight: 600;
  font-size: 23px;
  color: ${({ theme }) => theme.colors.white};
  padding-bottom: 30px;
  display: flex;
  word-wrap: anywhere;

  sup {
    font-size: 50%;
    margin-left: 3px;
    margin-top: -5px;
  }
`;

export const Navigation = styled.div`
  margin-bottom: -32px;

  .MuiTabs-root {
    height: 40px;
    width: 100%;
  }

  .MuiTabs-indicator {
    background-color: ${({ theme }) => theme.colors.green};
  }

  .MuiTabs-fixed .MuiTabs-flexContainer > button {
    margin-right: 32px;
    text-transform: none;
    color: rgba(208, 247, 255, 0.6);
  }

  .MuiTabs-fixed .MuiTabs-flexContainer > .Mui-selected {
    color: #2cac7e;
  }

  .MuiTabPanel-root {
    padding: 0 0 30px;
  }

  button {
    font-size: 16px;
    min-width: unset;
    line-height: 19px;
    padding: 0;
    margin-right: 10px;
    align-items: flex-start;
  }
`;
