import styled from 'styled-components';
import { IconWrapper } from '../Common/Icon/style';

export const Wrapper = styled.div<{ open?: boolean }>`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-bottom: 20px;
  position: relative;
`;

export const SearchInputWrapper = styled.div`
  position: relative;
  flex: 1;

  ${IconWrapper} {
    position: absolute;
    top: 15px;
    left: 15px;
  }
`;

export const SearchInput = styled.input`
  border: 1px solid ${({ theme }) => theme.colors.graphite};
  border-radius: 8px;
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0);
  background-color: ${({ theme }) => theme.colors.blackIII};
  padding: 12px 12px 12px 40px;
  font-size: 16px;
  line-height: 20px;
  color: ${({ theme }) => theme.colors.grey};
  width: 100%;
`;

export const SearchResultsWrapper = styled.div<{
  open?: boolean;
}>`
  margin-top: 5px;
  display: flex;
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  z-index: 9999999;
  flex-direction: column;
  background-color: ${({ theme }) => theme.colors.blackIII};
  border-radius: 8px;
  border: 1px solid ${({ theme }) => theme.colors.graphite};
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0);
  opacity: ${({ open }) => (open ? 1 : 0)};
  transition: ${({ theme }) => theme.transition};
  pointer-events: ${({ open }) => (open ? 'all' : 'none')};
  transform: ${({ open }) => (open ? 'translateY(0)' : 'translateY(-20px)')};
  filter: ${({ open }) => (open ? 'none' : 'blur(6px)')};
`;

export const SearchItem = styled.div`
  padding: 12px;
  color: #fff;
  font-size: 14px;
  transition: all 300ms ease;
  cursor: pointer;

  &:first-of-type {
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
  }
  &:last-of-type {
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
  }

  &:hover {
    background-color: ${({ theme }) => theme.colors.blue};
  }
`;
