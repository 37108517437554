import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface UIState {
  dataBoardTab: string;
  showDeforestationComponent: boolean;
  showSidebar: boolean;
  sidebarVisible: boolean;
  showNotifications: boolean;
  showSavedRegions: boolean;
  mapToolsExpanded: boolean;
}

const initialState: UIState = {
  dataBoardTab: '0',
  showDeforestationComponent: false,
  showSidebar: false,
  sidebarVisible: false,
  showNotifications: false,
  showSavedRegions: false,
  mapToolsExpanded: true
};

export const uiSlice = createSlice({
  name: 'ui',
  initialState,
  reducers: {
    setDataBoardTab: (state, action: PayloadAction<string>) => {
      state.dataBoardTab = action.payload;
    },
    setShowDeforestationComponent: (state, action: PayloadAction<boolean>) => {
      state.showDeforestationComponent = action.payload;
    },
    toggleDeforestationComponent: (state) => {
      state.showDeforestationComponent = !state.showDeforestationComponent;
      document.body.classList.toggle('deforestation');
    },
    setShowSidebar: (state, action: PayloadAction<boolean>) => {
      state.showSidebar = action.payload;
      state.sidebarVisible = action.payload;
    },
    setSidebarVisible: (state, action: PayloadAction<boolean>) => {
      state.sidebarVisible = action.payload;
    },
    setShowNotifications: (state, action: PayloadAction<boolean>) => {
      state.showNotifications = action.payload;
    },
    setShowSavedRegions: (state, action: PayloadAction<boolean>) => {
      state.showSavedRegions = action.payload;
    },
    setMapToolsExpanded: (state, action: PayloadAction<boolean>) => {
      state.mapToolsExpanded = action.payload;
    },
    resetUIState: (state) => {
      return {
        ...initialState,
        mapToolsExpanded: state.mapToolsExpanded
      };
    }
  }
});

export default uiSlice.reducer;
export const {
  setDataBoardTab,
  setShowDeforestationComponent,
  setShowSidebar,
  setSidebarVisible,
  toggleDeforestationComponent,
  resetUIState,
  setShowNotifications,
  setShowSavedRegions,
  setMapToolsExpanded
} = uiSlice.actions;
