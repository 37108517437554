import styled, { css } from 'styled-components';

export const SidebarWrapper = styled.div<{ isOpen: boolean; visible?: boolean }>`
  position: fixed;
  display: flex;
  flex-direction: column;
  top: 72px;
  right: 0;
  width: 632px;
  height: calc(100vh - 72px);
  z-index: 7;
  transform: translate3d(${({ isOpen, visible }) => (isOpen && visible ? '0' : '100%')}, 0, 0);
  box-shadow: -7px 0 8px rgba(0, 0, 0, 0.14);
  pointer-events: ${({ isOpen }) => (isOpen ? 'all' : 'none')};
  transition: ${({ theme }) => theme.transition};
  background: ${({ theme }) => theme.colors.blackII};
`;

export const CloseIconWrapper = styled.div`
  position: absolute;
  top: 32px;
  right: 32px;
  cursor: pointer;
  transition: ${({ theme }) => theme.transition};
  z-index: 8;

  &:hover {
    opacity: 0.6;
  }
`;

export const CommonCss = css`
  padding: 32px;
`;

export const SidebarContent = styled.div`
  ${CommonCss};
  display: flex;
  flex-direction: column;
  background-color: ${({ theme }) => theme.colors.blackII};
  border-top: 1px solid rgba(208, 247, 255, 0.2);
  border-bottom: 1px solid rgba(208, 247, 255, 0.2);
  flex: 1;
  overflow-y: scroll;
  overflow-x: visible;

  &::-webkit-scrollbar {
    display: none;
  }
`;

export const HeaderFooterContent = styled.div`
  ${CommonCss};
  background-color: transparent;
  position: relative;
`;

export const FooterContent = styled(HeaderFooterContent)`
  z-index: 10;
  background-color: ${({ theme }) => theme.colors.blackIII};
`;
