import styled from 'styled-components';
import { IconWrapper } from '../Common/Icon/style';

export const ContentSkeletonWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-top: 15px;
`;

export const ContentSkeletonNavWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
  z-index: 5;
`;

export const ControlsAreaWrapper = styled.div`
  display: flex;
  padding: 0 0 24px;
  gap: 20px;
  width: 100%;
`;

export const LastUpdated = styled.p`
  margin-top: 24px;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  color: ${({ theme }) => theme.colors.lightBlueTransparent};
`;

export const ContentWrapper = styled.div`
  position: relative;
`;

export const ToggleButton = styled.button<{ visible?: boolean }>`
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
  top: 20px;
  left: -60px;
  z-index: 10;
  cursor: pointer;
  transition: ${({ theme }) => theme.transition};
  width: 40px;
  height: 40px;
  background: rgba(18, 22, 36, 0.85);
  border: 1px solid #121624;
  backdrop-filter: blur(2px);
  border-radius: 8px;
  opacity: ${({ visible }) => (visible ? 1 : 0)};
  pointer-events: ${({ visible }) => (visible ? 'all' : 'none')};

  ${IconWrapper} {
    transform: rotate(180deg);
    transition: ${({ theme }) => theme.transition};
  }

  &:hover {
    ${IconWrapper} {
      transform: rotate(180deg) translate3d(2px, 0, 0);
    }
  }
`;
