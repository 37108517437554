import { ReactNode, useEffect, useState } from 'react';
import { Map, NavigationControl, ViewStateChangeEvent } from 'react-map-gl';
import { toast } from 'react-toastify';
import { INITIAL_MAP_VIEW_STATE } from '../../constants/map';
import { usePolygonContext } from '../../context/Polygon';
import { useAppSelector } from '../../redux/hooks';
import DrawControl from './DrawControl';
import DrawInfoComponent from './DrawInfo';
import { DrawStyles, MapContainer } from './style';
import StyleChange from './StyleChange';
import { useMapLogic } from './useMapLogic';
import { useMapSources } from './useMapSources';
interface MapProps {
  children?: ReactNode;
  isInteractive?: boolean;
}

const MapComponent = ({ children, isInteractive = false }: MapProps) => {
  const {
    onDrawModeChange,
    handleDrawCreate,
    handleDrawUpdate,
    handleOnClick,
    mapStyle,
    handleStyleChange,
    handleMouseMove
  } = useMapLogic();

  const {
    renderCoveredArea,
    renderPublicProjects,
    renderSplitDrawnPolygonOnTile,
    renderSplitDrawnPolygonOutsideTile,
    renderUserRegions,
    renderRasterImageSource
  } = useMapSources();

  const [viewState, setViewState] = useState(INITIAL_MAP_VIEW_STATE);
  const { resetPolygonData } = usePolygonContext();
  const { selectedPolygon, deforestationRasterImageUrl } = useAppSelector((state) => state.regionState);
  const { isDrawing, drawnFeatures } = useAppSelector((state) => state.drawState);
  const { showSidebar, sidebarVisible } = useAppSelector((state) => state.uiState);

  /**
   * Handling map moving with mouse/touch events
   * @param {ViewStateChangeEvent} e Map view state event change param
   */
  const handleOnMove = (e: ViewStateChangeEvent) => {
    setViewState({ ...e.viewState });
  };

  useEffect(() => {
    const handleEscapeKey = (e: KeyboardEvent) => {
      if (e.key === 'Escape') {
        ['hint-draw-first', 'hint', 'hint-draw-second', 'hint'].forEach(toast.dismiss);
        resetPolygonData(false);
      }
    };
    if (isDrawing) {
      window.addEventListener('keyup', handleEscapeKey);
    }
    return () => {
      window.removeEventListener('keyup', handleEscapeKey);
    };
  }, [isDrawing, resetPolygonData]);

  return (
    <MapContainer className="mapboxgl-map" isInteractive={isInteractive} shouldShrink={showSidebar && sidebarVisible}>
      <Map
        {...viewState}
        onMove={handleOnMove}
        onClick={!isDrawing ? handleOnClick : undefined}
        onMouseMove={handleMouseMove}
        mapStyle={mapStyle}
        preserveDrawingBuffer
        id="mapRoot"
        data-test-id="map"
        style={{
          width: '100vw',
          height: '100vh'
        }}
        renderWorldCopies={false}
        mapboxAccessToken={process.env.REACT_APP_MAPBOX_TOKEN}
      >
        {isDrawing ? (
          <DrawControl
            defaultMode="draw_polygon"
            onCreate={handleDrawCreate}
            keybindings={false}
            onUpdate={handleDrawUpdate}
            userProperties={true}
            styles={DrawStyles}
            displayControlsDefault={false}
            onModeChange={onDrawModeChange}
          />
        ) : null}
        {drawnFeatures.length === 0 && isDrawing && !selectedPolygon?.userId && !selectedPolygon?.getInDrawMode ? (
          <DrawInfoComponent />
        ) : null}
        <NavigationControl position="bottom-right" showCompass={false} />
        <StyleChange setStyle={handleStyleChange} currentStyle={mapStyle} />
        {isInteractive ? renderCoveredArea() : null}
        {isInteractive ? renderSplitDrawnPolygonOnTile() : null}
        {isInteractive ? renderSplitDrawnPolygonOutsideTile() : null}
        {isInteractive ? renderUserRegions() : null}
        {isInteractive ? renderPublicProjects() : null}
        {isInteractive && deforestationRasterImageUrl ? renderRasterImageSource() : null}
        {children}
      </Map>
    </MapContainer>
  );
};

export default MapComponent;
