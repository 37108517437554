import { useEffect, useState } from 'react';
import { CookiesProvider } from 'react-cookie';
import { BrowserRouter, Route, Routes, useParams } from 'react-router-dom';
import { toast, ToastContainer } from 'react-toastify';
import { ThemeProvider } from 'styled-components';
import CookiesBar from './components/CookiesBar';
import MobileBanner from './components/MobileBanner';
import PrivateRoutes from './components/PrivateRoute';
import AdminPrivateRoutes from './components/PrivateRoute/PrivateAdminRoute';
import ActivateAccount from './pages/Activate';
import AdminPage from './pages/Admin/Admin';
import AdminLoginPage from './pages/Admin/AdminLogin';
import HomePage from './pages/Explore';
import ForgotPasswordPage from './pages/ForgotPassword';
import LoginPage from './pages/Login';
import FolderPage from './pages/Portfolio/Folder';
import PortfolioPage from './pages/Portfolio/Portfolio';
import ProjectPage from './pages/Portfolio/Project';
import SummaryPage from './pages/Portfolio/Summary';
import UnassignedPage from './pages/Portfolio/Unassigned';
import PrivacyPolicyPage from './pages/PrivacyPolicy';
import RegisterPage from './pages/Register';
import ResetPasswordPage from './pages/ResetPassword';
import TermsOfUsePage from './pages/TermsOfUse';
import { openModal } from './redux/features/modal/modal-slice';
import { useAppDispatch, useAppSelector } from './redux/hooks';
import { GlobalStyle } from './styles/Global';
import { theme } from './theme/Theme';

const App = () => {
  const [showMobileBanner, setShowMobileBanner] = useState(true);
  //const { run } = useAppSelector((state) => state.joyrideState);
  const { user } = useAppSelector((state) => state.userState);
  const dispatch = useAppDispatch();
  //const { handleJoyrideCallback } = useJoyride();
  const { apiKey } = useParams();

  useEffect(() => {
    // we need to check also if there is no apiKey pending in the params, if so skip opening
    if (!localStorage.getItem('welcomeJoyrideDisplayed') && !!user && !apiKey) {
      dispatch(openModal('joyrideWelcome'));
    }
  }, [apiKey, dispatch, user]);

  //const shouldRun = useMemo(() => !!user && !localStorage.getItem('skipJoyride') && run, [run, user]);

  return (
    <div>
      <ThemeProvider theme={theme}>
        {/*<Joyride
          steps={steps}
          disableCloseOnEsc
          continuous
          run={!!shouldRun}
          showProgress
          tooltipComponent={JoyrideTooltip as ElementType<TooltipRenderProps>}
          callback={handleJoyrideCallback}
          scrollOffset={72}
          styles={{
            options: {
              zIndex: 999,
              primaryColor: '#878beb',
              backgroundColor: '#1E222F',
              textColor: '#fff',
              arrowColor: 'var(--blackII)'
            }
          }}
        />*/}
        <MobileBanner onConfirm={() => setShowMobileBanner(false)} active={showMobileBanner} />
        <GlobalStyle />
        <CookiesBar />
        <ToastContainer
          position={toast.POSITION.TOP_RIGHT}
          theme="colored"
          newestOnTop
          pauseOnHover={false}
          hideProgressBar
          closeButton={false}
        />
        <BrowserRouter>
          <CookiesProvider>
            <Routes>
              <Route element={<PrivateRoutes />}>
                <Route path="/:apiKey?" element={<HomePage />} />
                <Route path="/portfolio/" element={<PortfolioPage />}>
                  <Route path="summary" element={<SummaryPage />} />
                  <Route path="unassigned" element={<UnassignedPage />} />
                  <Route path="folder/:id" element={<FolderPage />} />
                  <Route path="folder/:id/project/:projectId" element={<ProjectPage />} />
                </Route>
              </Route>
              <Route element={<AdminPrivateRoutes />}>
                <Route path="/admin/:tab?" element={<AdminPage />} />
              </Route>
              <Route path="/login/admin" element={<AdminLoginPage />} />
              <Route path="/activate/:activationToken" element={<ActivateAccount />} />
              <Route path="/forgot-password" element={<ForgotPasswordPage />} />
              <Route path="/login" element={<LoginPage />} />
              <Route path="/privacy-policy" element={<PrivacyPolicyPage />} />
              <Route path="/register" element={<RegisterPage />} />
              <Route path="/reset-password/:resetToken" element={<ResetPasswordPage />} />
              <Route path="/terms-of-use" element={<TermsOfUsePage />} />
            </Routes>
          </CookiesProvider>
        </BrowserRouter>
      </ThemeProvider>
    </div>
  );
};

export default App;
