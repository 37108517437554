import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export enum DataLayerEnum {
  Deforestation = 'deforestation'
  //'Above Ground Biomass' = 'above-ground-biomass'
  //'Forest Loss' = 'forest-loss',
  //'Vegetation Density' = 'vegetation-density',
  //'Canopy Heights' = 'canopy-heights'
}

interface MapState {
  sources: string[];
  dataLayer: DataLayerEnum | null;
}

const initialState: MapState = {
  sources: ['default', 'user', 'public'],
  dataLayer: null
};

export const mapSlice = createSlice({
  name: 'map',
  initialState,
  reducers: {
    setSources: (state, action: PayloadAction<string[]>) => {
      state.sources = action.payload;
    },
    setDataLayer: (state, action: PayloadAction<DataLayerEnum | null>) => {
      state.dataLayer = action.payload;
    }
  }
});
export default mapSlice.reducer;
export const { setSources, setDataLayer } = mapSlice.actions;
