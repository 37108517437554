import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import type { Placement, TooltipRenderProps } from 'react-joyride';

export type JoyrideStep = {
  target: string;
  content: TooltipRenderProps['step']['content'];
  mediaUrl?: string;
  mediaType?: 'image' | 'video';
  title?: string;
  disableBeacon?: boolean;
  placement?: Placement;
  forceLastStep?: boolean;
};

interface JoyrideSlice {
  steps: JoyrideStep[];
  completedSteps?: JoyrideStep[];
  run?: boolean;
}

const initialState: JoyrideSlice = {
  steps: [],
  completedSteps: JSON.parse(localStorage.getItem('completedSteps') || '[]') as JoyrideStep[],
  run: false
};

export const joyrideSlide = createSlice({
  name: 'joyride',
  initialState,
  reducers: {
    addSteps: (state, action: PayloadAction<JoyrideSlice['steps']>) => {
      if (localStorage.getItem('skipJoyride')) {
        state.run = false;
        return;
      }

      const stepSet = new Set(state.steps.map((step) => step?.target));
      const filteredSteps = action.payload.filter((step) => !stepSet.has(step.target));
      const newSteps = [...filteredSteps, ...state.steps];
      if (newSteps.length > 0) {
        const itemThatShouldBeLast = newSteps.find((step) => step.forceLastStep);
        if (itemThatShouldBeLast) {
          newSteps.splice(newSteps.indexOf(itemThatShouldBeLast), 1);
          newSteps.push(itemThatShouldBeLast);
        }
        state.steps = newSteps;
      }
    },

    removeSteps: (state, action: PayloadAction<JoyrideSlice['steps']>) => {
      const newSteps = state.steps?.filter((step) => {
        // check if any of the steps is not in completedSteps, then remove it
        return !action.payload.find((completedStep) => completedStep.content === step.content);
      });
      state.steps = newSteps;
      state.run = false;
    },

    addToCompletedSteps: (state, action: PayloadAction<JoyrideStep>) => {
      state.completedSteps = [...(state.completedSteps || []), action.payload];
    },
    resetJoyrideState: (state) => {
      state.steps = initialState.steps;
      state.run = initialState.run;
    },
    rerunJoyrideWithNoCleanup: (state) => {
      state.run = true;
    },
    rerunJoyride: (state) => {
      state.completedSteps = [];
      state.run = state.run === false ? true : false;
    }
  }
});

export default joyrideSlide.reducer;
export const {
  addSteps,
  addToCompletedSteps,
  resetJoyrideState,
  rerunJoyride,
  removeSteps,
  rerunJoyrideWithNoCleanup
} = joyrideSlide.actions;
