import { useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useMap } from 'react-map-gl';
import { toast } from 'react-toastify';
import { INITIAL_MAP_VIEW_STATE } from '../../../constants/map';
import { useRemoveRegionMutation } from '../../../redux/api/regionApi';
import { resetDrawSliceState } from '../../../redux/features/draw/draw-slice';
import { setDataLayer } from '../../../redux/features/map/map-slice';
import { closeModal } from '../../../redux/features/modal/modal-slice';
import { setSelectedPolygon } from '../../../redux/features/region/region-slice';
import { setShowSidebar } from '../../../redux/features/ui/ui-slice';
import { useAppDispatch, useAppSelector } from '../../../redux/hooks';
import Button from '../../Common/Button';
import Modal from '../../Common/Modal';
import Spacer from '../../Common/Spacer';
import { ButtonWrapper, Subtitle, Title } from '../ModalCommon.style';
import { ModalSx } from './style';

const DeleteRegionModal = () => {
  const dispatch = useAppDispatch();
  const { mapRoot } = useMap();
  const { t } = useTranslation();
  const [deleteRegion, { isLoading, error, reset }] = useRemoveRegionMutation();
  const { selectedPolygon, regionToEditOrDelete } = useAppSelector((state) => state.regionState);

  const handleDeleteRegion = useCallback(async () => {
    const data = await deleteRegion({ id: String(regionToEditOrDelete?.id) });

    if (!('error' in data)) {
      toast.success(t('Region deleted successfully'), {
        autoClose: 3000,
        toastId: 'delete-region-toast'
      });

      // if a region is set as selected, remove it
      if (regionToEditOrDelete?.userId === selectedPolygon?.userId) {
        dispatch(setSelectedPolygon(null));
        dispatch(resetDrawSliceState());
        dispatch(setDataLayer(null));
        dispatch(setShowSidebar(false));
        mapRoot?.flyTo(INITIAL_MAP_VIEW_STATE);
      }

      window.postMessage('regionUpdated', '*');

      dispatch(closeModal());
    }
  }, [
    deleteRegion,
    dispatch,
    mapRoot,
    regionToEditOrDelete?.id,
    regionToEditOrDelete?.userId,
    selectedPolygon?.userId,
    t
  ]);

  useEffect(() => {
    if (error && 'data' in error) {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-argument,@typescript-eslint/no-unsafe-member-access
      toast.error((error.data as any).message, {
        toastId: 'delete-region-modal-error',
        autoClose: 3000
      });
      reset();
    }
  }, [error, reset]);

  return (
    <Modal
      additionalSx={ModalSx}
      withCloseIcon={false}
      disableBackdropClick
      disableEscapeKeyDown
      dataTestId="delete-region-modal"
      modalType="deleteRegion"
    >
      <Title data-test-id="delete-region-modal-title">{t('Delete Saved Region')}</Title>
      <Subtitle data-test-id="delete-region-modal-subtitle">
        {t(`Are you sure you want to delete this region? This can't be undone`)}
      </Subtitle>
      <Spacer size={'30px 0 0'} />
      <ButtonWrapper>
        <Button
          variant="text-only-purple"
          fullWidth
          onClick={() => dispatch(closeModal())}
          dataTestId="delete-region-modal-cancel-button"
          additionalSx={{
            padding: 0,
            justifyContent: 'flex-start',
            fontSize: '14px',
            height: 'auto'
          }}
        >
          {t('Cancel')}
        </Button>
        <Button
          variant="red"
          additionalSx={{
            width: '190px'
          }}
          onClick={handleDeleteRegion}
          disabled={isLoading}
          dataTestId="delete-region-modal-delete-button"
        >
          {t('Yes, Delete')}
        </Button>
      </ButtonWrapper>
    </Modal>
  );
};

export default DeleteRegionModal;
