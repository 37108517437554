import { ReactNode } from 'react';
import { useSidebarContext } from '../../context/Sidebar';
import { useAppSelector } from '../../redux/hooks';
import Icon from '../Common/Icon';
import { CloseIconWrapper, FooterContent, HeaderFooterContent, SidebarContent, SidebarWrapper } from './style';

interface Props {
  content: ReactNode;
  header?: ReactNode;
  withCloseIcon?: boolean;
  footer?: ReactNode;
  isOpen?: boolean;
}

const Sidebar = ({ content, header, withCloseIcon = true, footer, isOpen = false }: Props) => {
  const { onClose } = useSidebarContext();
  const { sidebarVisible } = useAppSelector((state) => state.uiState);

  return (
    <>
      <SidebarWrapper isOpen={isOpen} data-test-id="sidebar-wrapper" visible={sidebarVisible}>
        {withCloseIcon ? (
          <CloseIconWrapper onClick={onClose} data-test-id="sidebar-close">
            <Icon variant="X" size={24} color={'white'} />
          </CloseIconWrapper>
        ) : null}
        {header ? <HeaderFooterContent data-test-id="sidebar-header">{header}</HeaderFooterContent> : null}
        <SidebarContent data-test-id="sidebar-content">{content}</SidebarContent>
        {footer ? <FooterContent data-test-id="sidebar-footer">{footer}</FooterContent> : null}
      </SidebarWrapper>
    </>
  );
};

export default Sidebar;
