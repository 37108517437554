import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { USER_SOURCE_NAME } from '../../../../constants/map';
import { useMapContext } from '../../../../context/Map';
import { useRegionContext } from '../../../../context/Region';
import { useAddRegionMutation } from '../../../../redux/api/regionApi';
import { resetDrawSliceState, setSplitDrawnPolygon } from '../../../../redux/features/draw/draw-slice';
import { setDataLayer } from '../../../../redux/features/map/map-slice';
import { closeModal } from '../../../../redux/features/modal/modal-slice';
import { useAppDispatch, useAppSelector } from '../../../../redux/hooks';
import { IRegionRequest } from '../../../../types/API/Region';
import Button from '../../../Common/Button';
import InformationBanner from '../../../Common/InformationBanner';
import Modal from '../../../Common/Modal';
import Spacer from '../../../Common/Spacer';
import { ButtonWrapper, Subtitle, Title } from '../../ModalCommon.style';
import { ModalSx } from '../style';

const EditRegionMissingDataModal = () => {
  const dispatch = useAppDispatch();
  const [addRegion, { isLoading, isError, reset }] = useAddRegionMutation();
  const { t } = useTranslation();
  const [reportMissingData, setReportMissingData] = useState(false);
  const [polygonMessage, setPolygonMessage] = useState('');
  const { user } = useAppSelector((state) => state.userState);
  const { drawnFeatures, drawnFeatureName, splitDrawnPolygon } = useAppSelector((state) => state.drawState);
  const { handleTileClick } = useMapContext();
  const { reportRegion } = useRegionContext();

  const handleChangeDiagnosticsSwitch = useCallback(() => {
    setReportMissingData((wasSent) => !wasSent);
  }, []);

  const prepareSubmitData = useCallback(
    (elToGet: GeoJSON.Feature<GeoJSON.Polygon>) => {
      const submitData: IRegionRequest = {
        name: drawnFeatureName,
        polygon: {
          type: elToGet.type,
          geometry: elToGet.geometry,
          properties: elToGet.properties as IRegionRequest['polygon']['properties']
        }
      };

      const dates = splitDrawnPolygon?.drawnDataOnTile.features
        .filter((el) => el.properties?.last_modified)
        .map((el) => new Date(String(el.properties?.last_modified)));

      if (dates) {
        submitData.dataUpdatedAt = dates.reduce((a, b) => (a > b ? a : b));
      }
      return submitData;
    },
    [drawnFeatureName, splitDrawnPolygon?.drawnDataOnTile.features]
  );

  /**
   * Send a request to the backend to request adding missing data parts for the regions
   * @param {IItem[]} regionsWithoutDataParts
   */
  const handleReportMissingPolygonParts = useCallback(
    async (elToGet: GeoJSON.Feature<GeoJSON.Polygon>) => {
      // Send a request to prioritize adding missing data to this region
      if (reportMissingData && user?.email) {
        if (polygonMessage.length === 0) {
          return;
        }
        const polygonCollection: GeoJSON.FeatureCollection = {
          type: 'FeatureCollection',
          features: [elToGet]
        };
        await reportRegion(polygonCollection, user.email);
      }
    },
    [polygonMessage.length, reportRegion, reportMissingData, user?.email]
  );

  const handleOnSave = useCallback(async () => {
    if (!isLoading) {
      const elToGet = Object.values(drawnFeatures)[0];
      await handleReportMissingPolygonParts(elToGet);
      const submitData = prepareSubmitData(elToGet);
      const data = await addRegion(submitData);

      reset();
      dispatch(resetDrawSliceState());
      dispatch(setDataLayer(null));
      if (!('error' in data)) {
        handleTileClick(data.data, USER_SOURCE_NAME);
      }
      dispatch(closeModal());
    }
  }, [
    isLoading,
    drawnFeatures,
    handleReportMissingPolygonParts,
    prepareSubmitData,
    addRegion,
    reset,
    dispatch,
    handleTileClick
  ]);

  useEffect(() => {
    if (isError) {
      reset();
      toast.error(t('Error saving region'));
    }
  }, [isError, reset, t]);

  const onCancel = useCallback(() => {
    dispatch(setSplitDrawnPolygon(undefined));
    dispatch(closeModal());
  }, [dispatch]);

  return (
    <Modal
      withCloseIcon={false}
      additionalSx={ModalSx}
      disableBackdropClick
      disableEscapeKeyDown
      dataTestId="edit-region-missing-data-modal"
      modalType="editRegionMissingData"
    >
      <Title data-test-id="edit-region-missing-data-modal-title">{t('Missing Data')}</Title>
      <Subtitle data-test-id="edit-region-missing-data-modal-subtitle">
        {user?.role === 'free'
          ? t(
              `Data is not available for some areas of your region. You can still save the region with incomplete data, or you can adjust your shape before continuing. Pro users may request data from our team. Contact ${
                process.env.REACT_APP_EMAIL_UPGRADE_TO as string
              } for more information.`
            )
          : t(
              'Data is not available for some areas of your region. You can still save the region with incomplete data, or you can adjust your shape before continuing'
            )}
      </Subtitle>
      <Spacer size="30px 0 0" />
      {user?.role !== 'free' ? (
        <>
          <InformationBanner
            checked={reportMissingData}
            onToggle={handleChangeDiagnosticsSwitch}
            text={t('Send a request to prioritize adding missing data to this region')}
            message={polygonMessage}
            setMessage={setPolygonMessage}
          />
          <Spacer size="20px 0 0" />
        </>
      ) : null}
      <ButtonWrapper>
        <Button
          variant="text-only-purple"
          fullWidth
          onClick={onCancel}
          disabled={isLoading}
          dataTestId="edit-region-missing-data-modal-button-cancel"
          additionalSx={{
            padding: 0,
            justifyContent: 'flex-start',
            fontSize: '14px',
            height: 'auto'
          }}
        >
          {t('Cancel')}
        </Button>
        <Button
          variant="purple"
          fullWidth
          dataTestId="edit-region-missing-data-modal-button-save"
          onClick={handleOnSave}
          disabled={(reportMissingData && polygonMessage.length === 0) || isLoading}
        >
          {reportMissingData ? t('Report and save') : t('Save anyway')}
        </Button>
      </ButtonWrapper>
    </Modal>
  );
};

export default EditRegionMissingDataModal;
