import styled from 'styled-components';
import { IconWrapper } from '../Common/Icon/style';

export const Wrapper = styled.div<{ open?: boolean }>`
  // if the wrapper is over 1440px, the map tools will be fixed to the max width minus the sidebar width
  position: fixed;
  top: 72px;
  left: 0;
  min-height: calc(100vh - 72px);
  background-color: rgba(18, 22, 36, 0.85);
  width: ${({ open }) => (open ? '370px' : '64px')};
  z-index: 99;
  box-shadow: 0 4px 4px rgba(0, 0, 0, ${({ open }) => (open ? '0.25' : '0')});
  backdrop-filter: blur(4px);
`;

export const Toggle = styled.button<{ open?: boolean }>`
  cursor: pointer;
  background-color: transparent;
  border: 0;
  width: 100%;
  padding: ${({ open }) => (open ? '20px' : '20px 20px 0')};
  color: ${({ theme }) => theme.colors.silver};
  font-size: ${({ open }) => (open ? '16px' : '0')};
  font-weight: 500;
  display: flex;
  justify-content: space-between;
  transition: ${({ theme }) => theme.transition};
  &:hover {
    ${IconWrapper} {
      opacity: ${({ open }) => (open ? 1 : 0.5)};
    }
  }

  ${IconWrapper} {
    transform: ${({ open }) => (open ? 'rotate(180deg)' : 'rotate(0deg)')};
    transition: ${({ theme }) => theme.transition};
    background-color: ${({ theme }) => theme.colors.white};
    margin-bottom: ${({ open }) => (open ? '0' : '32px')};
  }
`;

export const Options = styled.div<{ open?: boolean; dataBoardVisible?: boolean }>`
  padding: ${({ open }) => (open ? '20px' : '0')};
  display: flex;
  flex-direction: column;
  align-items: ${({ open }) => (open ? 'flex-start' : 'center')};
  overflow: visible scroll;
  width: 100%;
  height: ${({ open }) => (open ? 'calc(100vh - 90px)' : 'auto')};

  &::-webkit-scrollbar {
    display: none;
  }
`;

export const OptionsSection = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 32px;
  width: 100%;

  .MuiFormControlLabel-root {
    margin: 0;
    display: flex;
    align-items: center;

    .MuiButtonBase-root {
      margin: 0 12px 0 0;
    }

    .MuiFormControlLabel-label {
      color: ${({ theme }) => theme.colors.white};
      white-space: nowrap;
      font-weight: normal;
    }
  }
`;

export const OptionsSectionTitle = styled.h4<{ open?: boolean }>`
  color: ${({ theme }) => theme.colors.gray};
  font-size: 14px;
  font-weight: 700;
  margin-bottom: 20px;
  padding-bottom: 8px;
  display: flex;
  align-items: center;
  gap: 8px;
  border-bottom: 1px solid rgba(255, 255, 255, 0.08);
`;

export const OptionsButton = styled.button<{ isForPro?: boolean; open?: boolean }>`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  height: ${({ open }) => (open ? '46px' : '20px')};
  background-color: ${({ theme, open }) => (open ? theme.colors.silver : 'transparent')};
  border-radius: 8px;
  font-size: 14px;
  border: 0;
  font-weight: 700;
  color: ${({ theme }) => theme.colors.blackI};
  cursor: pointer;
  position: relative;
  margin-bottom: ${({ open }) => (open ? '10px' : '32px')};

  &:hover {
    ${IconWrapper} {
      opacity: ${({ open }) => (open ? 1 : 0.5)};
    }
  }

  &:before {
    display: ${({ isForPro }) => (isForPro ? 'block' : 'none')};
    content: 'PRO';
    position: absolute;
    top: -5px;
    right: -15px;
    color: ${({ theme }) => theme.colors.blackI};
    font-size: 12px;
    font-weight: bold;
    line-height: 14px;
    padding: 4px 8px;
    border-radius: 4px;
    background-color: ${({ theme }) => theme.colors.purple};
  }

  ${IconWrapper} {
    transition: ${({ theme }) => theme.transition};
    margin-right: ${({ open }) => (open ? '10px' : '0')};
  }

  &:disabled {
    background-color: rgba(220, 233, 245, 0.6);
    color: rgba(17, 26, 27, 0.6);
    cursor: not-allowed;

    > div {
      opacity: 0.6;
    }
  }
`;

export const DataLayersWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

export const DataLayer = styled.div<{ active?: boolean }>`
  padding: 16px 8px;
  display: grid;
  grid-template-columns: 24px 1fr 24px;
  grid-template-rows: 24px 1fr;
  grid-column-gap: 8px;
  grid-row-gap: 12px;
  background-color: ${({ active }) => (active ? 'rgba(25, 118, 210, 0.25)' : 'transparent')};
  border-bottom: 1px solid rgba(255, 255, 255, 0.08);
  transition: ${({ theme }) => theme.transition};

  &:last-of-type {
    border-bottom: 0;
  }
`;

export const DataLayerTitle = styled.h3`
  color: ${({ theme }) => theme.colors.white};
  text-transform: capitalize;
  grid-column: 2;
  grid-row: 1;
  overflow: hidden;
  max-width: calc(1fr - 48px);
  text-overflow: ellipsis;
  white-space: nowrap;
  font-size: 16px;
  line-height: 24px;
  font-weight: 500;
`;

export const DataLayerDescription = styled.p`
  grid-column: 1 / -1;
  grid-row: 2;
  font-size: 14px;
  color: #bfc1c5;
  line-height: 20px;
`;
export const DataLayerIcon = styled.div`
  grid-row: 1;
  grid-column: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 3px;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
`;

export const DataLayerRadio = styled.input.attrs({ type: 'radio' })`
  grid-row: span 1;
  grid-column: 3;
  justify-self: center;
  align-self: center;
  cursor: pointer;
  appearance: none;
  width: 24px;
  height: 24px;
  border: 2px solid #d1d5db;
  border-radius: 50%;
  background-color: white;
  transition: ${({ theme }) => theme.transition};
  position: relative;

  &:before {
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 12px;
    height: 12px;
    border-radius: 50%;
    background-color: ${({ theme }) => theme.colors.green};
    transition: ${({ theme }) => theme.transition};
    opacity: 0;
  }

  &:checked {
    border-color: ${({ theme }) => theme.colors.green};
    &:before {
      opacity: 1;
    }
  }
`;
